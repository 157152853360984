import { createMuiTheme } from '@material-ui/core/styles';

const baseOptions = {
    palette: {
        text: {
            primary: '#333'
        },
        highlight: {
            blue: '#98BAFF',
            green: '#26CC6F',
            red: '#E64040'
        },
        tag: {
            background: '#EEEEEE',
            foreground: '#999999'
        },
        action: {
            active: '#413BBE',
            hover: '#EFF8FD',
            selected: '#E1F0FB'
        },
        validation: {
            error: '#FF0000', // is empty when required, or bad data
            suggested: '#FFA500', // is empty when suggested
            optional: '#990CFF', // highlight changes for approval
            good: '#14A554'
        },
        contentBackground: {
            none: '#EBF6FD'
        },
        contentForeground: {
            none: '#35327c'
        },
        basePrimary: {
            none: '#35327c'
        },
        baseSecondary: {
            none: '#413BBE'
        },
        baseTertiary: {
            none: '#9693E9'
        },
        custom: {
            darkishGrey: '#555',
            lightishGrey: '#D5D5D5',
            mildlyDarkerGrey: '#bdbdbd',
            veryLightGrey: '#f5f5f5'
        },
        legend: {
            transfers: {
                newColor: '#60CEB9',
                urgentColor: '#ee1cc5',
                updatedColor: '#ee9a29',
                policeTransferColor: '#38BDEE'
            }
        },
        button: {
            alt: {
                backgroundColor: '#2B5FC8',
                foregroundColor: '#FFF',
                hoverBackgroundColor: '#4C6B99'
            },
            outline: {
                backgroundColor: 'none',
                foregroundColor: '#2B5FC8',
                borderColor: '#2B5FC8',
                hoverBackgroundColor: '#EEE'
            },
            icon: {
                foregroundColor: '#CDCDCD'
            },
            save: '#14A554',
            destroy: '#bb5a5a'
        },
        table: {
            header: {
                backgroundColor: '#35327C',
                foregroundColor: '#FFFFFF'
            },
            headerSecondary: {
                backgroundColor: '#eeeeee',
                foregroundColor: '#555555'
            },
            row: {
                backgroundColor: '#EFF8FD',
                backgroundAltColor: '#DCE4F0',
                hoverColor: 'white'
            },
            nestedRow: {
                backgroundColor: '#FFFFFF',
                hoverColor: 'white'
            },
            border: '#9693E9'
        }
    },
    sizes: {
        headerHeight: 72,
        footerHeight: 80,
        drawerWidth: 250,
        tasksDrawerWidth: 350,
        drawerWidthMinimized: 50,
        tabHeight: 56
    },
    funeralHome: 'none',
    typography: {
        title: {
            fontSize: '1.1rem'
        },
        display1: {
            fontSize: '1.6rem'
        },
        display2: {
            fontSize: '1.8rem'
        },
        display3: {
            fontSize: '2.4rem'
        },
        display4: {
            fontSize: '3.2rem'
        },
        fontSizes: {
            regular: '14px'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            dt: 1140,
            lg: 1280,
            xl: 1920,
            xxl: 2660
        },
        custom: {
            dt: 1140
        }
    },
    validationLabel: {
        lineHeight: '1 !important',
        position: 'absolute',
        top: '100%',
        border: '1px solid #E64040',
        background: 'white',
        padding: '2px 4px',
        display: 'block',
        zIndex: 2,
        fontSize: 12,
        margin: '-1px 5px',
        borderRadius: '0 0 3px 3px',
        maxWidth: 'calc(100% - 10px)',
        minWidth: 64
    },
    overrides: {
        MuiFormControl: {
            root: {
                marginBottom: 10 // added whitespace for validation messages
            }
        }
    }
};
export const theme = createMuiTheme(baseOptions);

export const recolour = (originalColour, comparisonColour = '#FFFFFF', percentDiff = 0.9) => {
    originalColour = /^#[0-9A-F]{6}$/i.test(originalColour) ? originalColour : '#000000';
    const f = percentDiff <= 1 ? percentDiff : percentDiff / 100;
    let c0 = (originalColour || '').replace(/#/, '');
    let c1 = (comparisonColour || '').replace(/#/, '');
    c0 = c0.match(/.{1,2}/g).map(oct => parseInt(oct, 16) * (1 - f));
    c1 = c1.match(/.{1,2}/g).map(oct => parseInt(oct, 16) * f);
    let ci = [0, 1, 2].map(i => Math.min(Math.round(c0[i] + c1[i]), 255));
    return (
        '#' +
        ci
            .reduce((a, v) => (a << 8) + v, 0)
            .toString(16)
            .padStart(6, '0')
    );
};
