import React, { Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import { DataTypeProvider, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { Grid, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { Plugin, Template, TemplateConnector, TemplatePlaceholder } from '@devexpress/dx-react-core';
import { withStyles } from '@material-ui/core/styles';
import { getOfficeFromKey } from '../../util/brands';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';
import { withSearchTerm } from '../../context/searchTerm';
import { AutoSizer } from 'react-virtualized';
import LinearProgress from '@material-ui/core/LinearProgress';
import { joinDefined } from '../../util/strings';
import BrandDot from '../../component/table/BrandDot';
import LinkButton from '../../component/form/LinkButton';

const getRowId = row => row.ID;

const BrandFormatter = props => {
    const { LegacyKey, ID, __typename } = props.row;
    const funeralHome = getOfficeFromKey(LegacyKey);
    const { color, label } = funeralHome ? funeralHome : { color: 'grey', label: 'unsure' };
    let path = 'Funeral' !== __typename ? '/prearrangement' : '/funeral';
    path = path + `/${LegacyKey}/${ID}`;
    return (
        <Fragment>
            <LinkButton href={path}>
                <BrandDot dotColour={color} label={LegacyKey} title={label} />
            </LinkButton>
        </Fragment>
    );
};

const NameFormatter = props => {
    const { FirstName, MiddleName, Surname, PlaceOfService } = props.row;
    const { Private } = PlaceOfService || {};
    return (
        <span>
            {!!Private && (
                <small
                    style={{
                        color: 'red',
                        border: '1px solid red',
                        borderRadius: 5,
                        padding: '2px 3px 1px 3px',
                        margin: '-3px 4px -3px 0',
                        background: '#FF000010',
                        fontSize: '0.633rem'
                    }}
                >
                    PRIVATE
                </small>
            )}
            {joinDefined([(Surname || '').toUpperCase(), FirstName, MiddleName], ' ')}
        </span>
    );
};

const BDMReadyFormatter = props => {
    return <span>{Number(props.row.FuneralDataStatus) > 1 ? 'Yes' : 'No'}</span>;
};

const ConfirmedFormatter = props => {
    return <span>{!!props.row.Confirmed ? 'Yes' : 'No'}</span>;
};

const ChecklistFormatter = props => {
    return <span>{Math.round(Number(props.row.AdminChecksComplete) * 100)}%</span>;
};

const BrandTypeProvider = props => {
    return <DataTypeProvider formatterComponent={BrandFormatter} {...props} />;
};

const NameTypeProvider = props => {
    return <DataTypeProvider formatterComponent={NameFormatter} {...props} />;
};

const ChecklistProvider = props => {
    return <DataTypeProvider formatterComponent={ChecklistFormatter} {...props} />;
};

const BDMReadyProvider = props => {
    return <DataTypeProvider formatterComponent={BDMReadyFormatter} {...props} />;
};

const ConfirmedTypeProvider = props => {
    return <DataTypeProvider formatterComponent={ConfirmedFormatter} {...props} />;
};

const CustomTableRowBase = ({ classes, selected, onToggle, ...restProps }) => (
    <VirtualTable.Row className={classes.hoverRow} {...restProps} onClick={onToggle} />
);

class VirtualTableLoadHook extends React.PureComponent {
    render() {
        const { threshold, onLoad } = this.props;
        return (
            <Plugin>
                <Template name="tableRow" predicate={({ tableRow }) => tableRow.type === 'data'}>
                    {params => (
                        <TemplateConnector>
                            {({ rows }, { toggleSelection }) => {
                                if (rows.length - rows.indexOf(params.tableRow.row) < threshold) {
                                    onLoad();
                                }
                                return (
                                    <CustomTableRow
                                        {...params}
                                        onToggle={e => {
                                            const cellText = document.getSelection();
                                            if (cellText.type === 'Range') e.stopPropagation();
                                            else toggleSelection({ rowIds: [params.tableRow.rowId] });
                                        }}
                                    />
                                );
                            }}
                        </TemplateConnector>
                    )}
                </Template>
            </Plugin>
        );
    }
}

const isColumnSortingApplied = (columnName, sorting) =>
    sorting.findIndex(sortingItem => sortingItem.field === columnName) > -1;

class SearchTable extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            funColumns: [
                //{ name: 'Type', title: 'Office' },
                { name: 'LegacyKey', title: 'Key' },
                { name: 'Name', title: 'Name' },
                { name: 'DateOfBirth', title: 'Date of Birth' },
                { name: 'DateOfService', title: 'Date of Service' },
                { name: 'Confirmed', title: 'Confirmed' },
                { name: 'AdminChecksComplete', title: 'Checklist' },
                { name: 'ExportDate', title: 'BDM sent' }
                // { name: 'ExportStatus', title: 'BDM status' }
            ],
            preColumns: [
                // { name: 'Type', title: 'Office' },
                { name: 'LegacyKey', title: 'Key' },
                { name: 'Name', title: 'Name' },
                { name: 'DateOfBirth', title: 'Date of Birth' },
                { name: 'FuneralFundName', title: 'Prepaid Fund' }
            ],
            // sorting: ,
            sortingStateColumnExtensions: [
                //{ columnName: 'Type', sortingEnabled: false },
                { columnName: 'LegacyKey', sortingEnabled: true },
                { columnName: 'Name', sortingEnabled: true },
                { columnName: 'DateOfBirth', sortingEnabled: true },
                { columnName: 'DateOfService', sortingEnabled: true },
                { columnName: 'Confirmed', sortingEnabled: false },
                { columnName: 'AdminChecksComplete', sortingEnabled: true },
                { columnName: 'ExportDate', sortingEnabled: true },
                { columnName: 'ExportStatus', sortingEnabled: false },
                { columnName: 'FuneralFundName', sortingEnabled: false }
            ]
        };

        this.changeSorting = sorting => {
            const { columnName } = sorting[0];
            if (
                !(columnName === 'LegacyKey' || columnName === 'Name') &&
                !isColumnSortingApplied(columnName, this.props.sortBy)
            ) {
                sorting[0].direction = 'desc';
            }

            let sortBy = [];
            for (let s of sorting) {
                if (s.columnName === 'Name') {
                    sortBy.push({ field: 'Surname', direction: s.direction === 'asc' ? 'ASC' : 'DESC' });
                    sortBy.push({ field: 'FirstName', direction: s.direction === 'asc' ? 'ASC' : 'DESC' });
                }
                // else if (s.columnName === 'LegacyKey') {
                //     sortBy.push({ field: 'LastEdited', direction: s.direction === 'asc' ? 'ASC' : 'DESC' });
                // }
                else {
                    sortBy.push({ field: s.columnName, direction: s.direction === 'asc' ? 'ASC' : 'DESC' });
                }
            }
            this.props.setSortBy(sortBy);
        };
    }

    getSortingForGrid() {
        let sortBy = [];

        for (let s of this.props.sortBy) {
            // if (s.field === 'LastEdited') {
            //     sortBy.push({ columnName: 'LegacyKey', direction: s.direction === 'ASC' ? 'asc' : 'desc' });
            // } else
            if (s.field === 'Surname') {
                sortBy.push({ columnName: 'Name', direction: s.direction === 'ASC' ? 'asc' : 'desc' });
            } else {
                sortBy.push({ columnName: s.field, direction: s.direction === 'ASC' ? 'asc' : 'desc' });
            }
        }

        return sortBy;
    }

    render() {
        const { classes, history, results, loading, prearrangements, match } = this.props;
        const { preColumns, funColumns } = this.state;
        const isRecent = 'recent' === match.params.brand;
        const tableColumnExtensions = [
            { columnName: 'LegacyKey', width: 150 },
            { columnName: 'Name', wordWrapEnabled: true },
            { columnName: 'DateOfBirth', width: 130 },
            { columnName: 'DateOfService', width: 130 },
            { columnName: 'Confirmed', width: 100 },
            { columnName: 'AdminChecksComplete', width: 100 },
            { columnName: 'ExportDate', width: 130 }
        ];
        return (
            <AutoSizer disableWidth>
                {({ height }) => {
                    return (
                        <Paper>
                            <Grid
                                rows={results}
                                columns={!!prearrangements ? preColumns : funColumns}
                                getRowId={getRowId}
                            >
                                <Template name="footer">
                                    <div>
                                        {/* keep existing footer template */}
                                        <TemplatePlaceholder />

                                        {/* add spinner */}
                                        {(() => {
                                            if (loading) {
                                                return <LinearProgress />;
                                            }
                                        })()}
                                    </div>
                                </Template>

                                <BrandTypeProvider for={['LegacyKey']} classes={classes} isFuneral={!prearrangements} />
                                <NameTypeProvider for={['Name']} classes={classes} />
                                <ConfirmedTypeProvider for={['Confirmed']} classes={classes} />
                                <ChecklistProvider for={['AdminChecksComplete']} classes={classes} />
                                <BDMReadyProvider for={['FuneralDataStatus']} classes={classes} />
                                <SelectionState
                                    onSelectionChange={selection => {
                                        const selectedRow = results.filter(({ ID }) => ID === selection[0])[0];
                                        let path = !!prearrangements ? '/prearrangement' : '/funeral';
                                        path = path + `/${selectedRow.LegacyKey}/${selectedRow.ID}`;
                                        history.push(path);
                                    }}
                                />
                                {(!isRecent && (
                                    <SortingState
                                        columnSortingEnabled
                                        sorting={this.getSortingForGrid()}
                                        onSortingChange={this.changeSorting}
                                        columnExtensions={this.state.sortingStateColumnExtensions}
                                    />
                                )) || <SortingState columnSortingEnabled={false} />}
                                {/*<IntegratedSorting />*/}

                                <VirtualTable height={height} columnExtensions={tableColumnExtensions} />
                                <TableHeaderRow showSortingControls={!isRecent} />
                                <VirtualTableLoadHook threshold={3} onLoad={this.props.onLoadMore} />
                            </Grid>
                        </Paper>
                    );
                }}
            </AutoSizer>
        );
    }
}

const styles = ({ palette }) => ({
    spinner: {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 16px)',
        top: '98%'
    },
    hoverRow: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: palette.action.selected
        }
    }
});
export const CustomTableRow = withStyles(styles)(CustomTableRowBase);

export default compose(withSearchTerm, withRouter, withStyles(styles))(SearchTable);
