import { isNullOrUndefined } from './objects';
import { sanitizeAndAppendFuneralData, sanitizeCateringStaffAllocation } from '../page/calendar/AppointmentConstants';
import { isRelatedObjectUndefined } from './graphql';
import { diff } from './functions';

/**
 * determines if the contact is defined
 * @param {Object} contact Any database object
 */
export function isContactDefined(contact) {
    return contact && contact.ID !== '0';
}

/**
 * determines if a foreign key object is defined
 * @param {Object} obj Any database object
 * @param {String|null} nestedProperty  Name of field or array key
 */
export function isRelatedObjectDefined(obj, nestedProperty = null) {
    if (!nestedProperty) return !isRelatedObjectUndefined(obj);

    return isRelatedObjectDefined(obj) && isRelatedObjectDefined(obj[nestedProperty]);
}

/**
 * Sanitise the foreign key from an object to make it save-worthy
 * @param {Object} originalObject
 * @param {String} propertyPath
 * @param {boolean} clone to create a clone or modify original
 */
export function sanitizeObjectWithFK(originalObject, propertyPath, clone = true) {
    if (!originalObject || !propertyPath) return originalObject;

    const objectWithFK = clone ? { ...originalObject } : originalObject;

    if (isContactDefined(objectWithFK[propertyPath])) {
        // if we have an ID, save the relationship
        objectWithFK[propertyPath + 'ID'] =
            objectWithFK[propertyPath].ID ||
            (!!objectWithFK[propertyPath].node ? objectWithFK[propertyPath].node.ID : '0');
    }
    delete objectWithFK[propertyPath]; // prune the related object

    return objectWithFK;
}

/**
 * Sanitise multiple foreign keys from an object to make it save-worthy
 * @param {*} objectWithFK
 * @param {Array.<string>} propertyPaths
 */
export function sanitizeObjectWithFKs(objectWithFK, propertyPaths) {
    if (!objectWithFK) return objectWithFK;

    for (let x = 0; x < propertyPaths.length; x++) {
        objectWithFK = sanitizeObjectWithFK(objectWithFK, propertyPaths[x], false);
    }

    return { ...objectWithFK };
}

/**
 * Sanitise the object to make it save worthy
 * @param {*} obj
 * @param {Array.<string>} fields
 */
export function sanitizeObject(obj, fields) {
    if (!obj) return obj;

    let clone = { ...obj };

    for (let x = 0; x < fields.length; x++) {
        if (obj[fields[x]] !== undefined) delete obj[fields[x]];
    }

    return clone;
}

/**
 * sanitizes an array (removes nodes)
 */
export function sanitizeArray(array, onSanitizeItem) {
    const sanitized = [];
    for (let x = 0; x < array.edges.length; x++) {
        const item = onSanitizeItem ? onSanitizeItem({ ...array.edges[x].node }) : { ...array.edges[x].node };

        sanitized.push(item);
    }
    return sanitized;
}

/**
 * sanitizes an array (removes field from a node)
 */
export function sanitizeFieldFromArray(array, field) {
    return array.map(item => {
        delete item[field];
        return item;
    });
}

/**
 * flattens a booking object
 */
export const flattenBookableObjects = (fieldNames, data) => {
    fieldNames
        .map(fieldName => data[fieldName])
        .filter(bookingDataObject => !isNullOrUndefined(bookingDataObject))
        .forEach(bookingDataObject => {
            //get the array
            let bookableArray = [];
            if (bookingDataObject.length > 0) bookableArray = bookingDataObject;
            else if (bookingDataObject.edges && bookingDataObject.edges.length > 0)
                bookableArray = bookingDataObject.edges.map(({ node }) => node);
            else bookableArray = [bookingDataObject];

            //iterate over
            bookableArray.forEach(bookable => {
                if (bookable.BookingItems && bookable.BookingItems.edges) {
                    bookable.BookingItems = bookable.BookingItems.edges.map(
                        ({ node: { Product, Variation, ...item } }) => {
                            return {
                                ...item,
                                Product,
                                Variation,
                                ProductID: !!Product ? Product.ID : null,
                                VariationID: !!Variation ? Variation.ID : null
                            };
                        }
                    );
                } else if (isNullOrUndefined(bookable.BookingItems)) {
                    bookable.BookingItems = [];
                }
            });
        });
};

export const prepareBookingsForSave = (rawBookings, eventType = null, funeralID = null) => {
    return rawBookings.map(b => {
        const item = { ...b };
        item.ContactID = item.Contact ? item.Contact.ID : null;
        if (eventType && funeralID) item[eventType + 'VenueID'] = funeralID; // attach booking to funeral
        delete item['Responded'];
        delete item['Responses'];
        delete item['Contact'];
        if (item.BookingItems) {
            item.BookingItems = (!!item.BookingItems.edges
                ? item.BookingItems.edges.map(e => e.node)
                : item.BookingItems
            ).map(x => {
                return {
                    ID: x.ID || null,
                    ProductID: x.ProductID,
                    Quantity: x.Quantity,
                    VariationID: x.VariationID,
                    Returns: x.Returns,
                    Comment: x.Comment,
                    UnitPrice: x.UnitPrice,
                    Total: x.Total
                };
            });
        }
        return item;
    });
};

const prepareVenueForSave = (saveObj, stateObj, originals = [], eventType, funeralID) => {
    saveObj.LocationID = !!stateObj.Location ? stateObj.Location.ID : null;
    delete saveObj.Location;
    delete saveObj.EventConflicts;
    if (saveObj.Bookings) {
        saveObj.Bookings = prepareBookingsForSave(stateObj.Bookings, eventType, funeralID);
    }
    if (stateObj.StaffAllocations) {
        const eventID = stateObj.ID || null;
        const originalObj = originals && originals.find(obj => obj && obj.ID === eventID);
        const unchanged = originalObj
            ? stateObj.StaffAllocations.filter(newMe => {
                  const oldMe = originalObj.StaffAllocations.find(obj => obj.ID === newMe.ID);
                  if (oldMe) {
                      return Object.keys(diff(oldMe, newMe, true)).length < 1;
                  }
                  return false;
              }).map(obj => obj.ID)
            : [];
        const calEventType =
            'Committal' === eventType && 'Cremated' === stateObj.CrematedOrBuried ? 'Disposal' : eventType;

        saveObj.StaffAllocations = stateObj.StaffAllocations.map(obj => {
            if (!!obj.ID && unchanged.includes(obj.ID)) return { ID: obj.ID };
            return sanitizeAndAppendFuneralData(calEventType, funeralID, eventID, obj);
        });
    }
    return saveObj;
};

export const prepareServiceForSave = (saveData, stateData, original) => {
    if (isNullOrUndefined(saveData.PlaceOfService.ID))
        saveData.PlaceOfService.ID = stateData.PlaceOfService.ID || stateData.PlaceOfServiceID || null;
    prepareVenueForSave(
        saveData.PlaceOfService,
        stateData.PlaceOfService,
        [original.PlaceOfService],
        'Service',
        stateData.ID
    );
    if (['No Service No Attendance', 'Graveside'].indexOf(stateData.PlaceOfService.Type) > -1) {
        saveData.PlaceOfService.LocationID = null;
        if (stateData.PlaceOfService.Bookings)
            saveData.PlaceOfService.Bookings = stateData.PlaceOfService.Bookings.filter(b => !!b.ID).map(b => {
                return { ID: b.ID, Cancelled: true };
            });
    }
};

export const prepareDisposalForSave = (saveData, stateData, original) => {
    saveData.Disposal.FuneralID = stateData.ID;
    prepareVenueForSave(saveData.Disposal, stateData.Disposal, [original.Disposal], 'Committal', stateData.ID);
    if (saveData.Disposal.DisposalBookingItems) {
        if (saveData.Disposal.DisposalBookingItems.length > 0) {
            saveData.Disposal.DisposalBookingItems = saveData.Disposal.DisposalBookingItems.map(x => {
                return {
                    ID: x.ID || null,
                    ProductID: x.ProductID,
                    Quantity: x.Quantity,
                    VariationID: x.VariationID,
                    Returns: x.Returns,
                    Comment: x.Comment,
                    UnitPrice: x.UnitPrice,
                    Total: x.Total
                };
            });
        }
    }
};

export const prepareViewingsForSave = (saveData, stateData, original) => {
    saveData.PlaceOfViewingItems = stateData.PlaceOfViewingItems.map(stateViewing => {
        const saveViewing = { ...stateViewing };
        return prepareVenueForSave(saveViewing, stateViewing, original.PlaceOfViewingItems, 'Viewing', stateData.ID);
    });
};

export const prepareRefreshmentsForSave = (saveData, stateData, original) => {
    if (stateData.RefreshmentsVenue.Type === 'Not Required') {
        saveData.RefreshmentsVenue.OffsiteAddressLine1 = null;
        saveData.RefreshmentsVenue.OffsiteSuburb = null;
        saveData.RefreshmentsVenue.OffsiteState = null;
        saveData.RefreshmentsVenue.OffsiteCountry = null;
        saveData.RefreshmentsVenue.OffsitePostCode = null;
        saveData.RefreshmentsVenue.LocationID = null;
        saveData.RefreshmentsVenue.Location = null;
        if (stateData.RefreshmentsVenue.Bookings)
            saveData.RefreshmentsVenue.Bookings = stateData.RefreshmentsVenue.Bookings.filter(b => !!b.ID).map(b => {
                return { ID: b.ID, Cancelled: true };
            });
        saveData.RefreshmentsVenue.StaffAllocations = [];
        saveData.RefreshmentsVenue.CateringStaffAllocations = [];
    } else {
        prepareVenueForSave(
            saveData.RefreshmentsVenue,
            stateData.RefreshmentsVenue,
            [original.RefreshmentsVenue],
            'Refreshments',
            stateData.ID
        );
        if (stateData.RefreshmentsVenue.Type === 'Venue') {
            saveData.RefreshmentsVenue.OffsiteAddressLine1 = null;
            saveData.RefreshmentsVenue.OffsiteSuburb = null;
            saveData.RefreshmentsVenue.OffsiteState = null;
            saveData.RefreshmentsVenue.OffsiteCountry = null;
            saveData.RefreshmentsVenue.OffsitePostCode = null;
        } else {
            saveData.RefreshmentsVenue.LocationID = null;
        }
        if (saveData.RefreshmentsVenue.CateringStaffAllocations) {
            saveData.RefreshmentsVenue.CateringStaffAllocations = stateData.RefreshmentsVenue.CateringStaffAllocations.map(
                e => sanitizeCateringStaffAllocation(stateData.RefreshmentsVenue.ID, e)
            );
        }
    }
    if (stateData.RefreshmentsVenue.NumberOfPeople) {
        saveData.RefreshmentsVenue.NumberOfPeople = Number(stateData.RefreshmentsVenue.NumberOfPeople);
    }
};

export const cancelAllBookings = (rawBookings = []) => {
    // Remove bookings: cancel if supplier already confirmed, cancelled or contacted, otherwise delete.
    return rawBookings
        .map(obj => {
            if (obj.Confirmed || obj.Cancelled) return { ...obj, Cancelled: true };
            const responses = (obj.Responses && (obj.Responses.edges || obj.Responses)) || [];
            if (responses.length > 0) return { ...obj, Cancelled: true };
            return null;
        })
        .filter(e => !!e);
};

/* Use this hardcoded value to link to addressbook for Mountain View Crematorium
 * TODO: replace with graphql values from either SiteConfig or CurrentUser
 * */
//export const MVC_ID = '6463';
export const Default_Catering_ID = '8265';
