import React, { Component, Fragment } from 'react';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { getOfficeFromKey } from '../../util/brands';
import { AltButton } from '../../component/form/PrimaryButton';
import Grid from '../../component/form/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import Table, {
    TableCell,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
    TableSecondaryHeaderRow
} from '../../component/form/Table';
import Query from 'react-apollo/Query';
import Spinner from '../../component/Spinner';
import PrintContent from '../../component/PrintContent';
import { flattenConnection } from '../../util/functions';
import { isNullOrUndefined } from '../../util/objects';
import cloneDeep from 'lodash.clonedeep';
import { formStyles } from '../mortuary/Common/FormFields';
import moment from 'moment';
import { joinDefined, prettyPhone } from '../../util/strings';
import { LinearProgress } from '@material-ui/core';
import PrintIcon from '../../component/icon/PrintIcon';
import { InlineField } from '../../component/form/Inline';
import LinkButton from '../../component/form/LinkButton';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { compose } from 'react-apollo';
import { CateringReportFragment } from './CateringReportFragment';
import { niceDateFromString, niceDateTimeFromString, niceTimeFromString } from '../../util/date';
import { isRelatedObjectDefined } from '../../util/bookable';
import Checkbox from '../../component/form/Checkbox';
import DatePickerField from '../../component/form/DatePickerField';
import { GQL_REFETCH_60M, subscribe } from '../../util/subscriptions';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../component/ErrorFallback';
import Separator from '../../component/form/Separator';

class CateringReport extends Component {
    constructor(props) {
        super(props);

        const saved = JSON.parse(sessionStorage.getItem('catering')) || {};
        const date = props.match.params.date || saved.date || moment().format('YYYYMMDD');

        this.state = {
            dateFrom: moment(date).format('YYYY-MM-DD'),
            dateTo: moment(date)
                .add(7, 'days')
                .format('YYYY-MM-DD'),
            data: [],
            showAll: false,
            showYear: false
        };

        this.formRef = React.createRef(); // printing
        this.cateringQueryRef = React.createRef();
        subscribe(this.cateringQueryRef, GQL_REFETCH_60M);
    }

    dateChange = e => {
        const { dateFrom, dateTo } = this.state;
        const diff = moment(dateTo).diff(dateFrom, 'days');
        // enforce a maximum of 7 days in the runsheet.
        const newDates = { dateFrom, dateTo };

        if (e.target.name === 'dateFrom') {
            if (moment(e.target.value).isAfter(dateTo)) {
                newDates.dateFrom = moment(e.target.value).format('YYYY-MM-DD');
                newDates.dateTo = moment(e.target.value)
                    .add(diff, 'days')
                    .format('YYYY-MM-DD');
            } else if (moment(e.target.value).isBefore(moment(dateTo).subtract(7, 'days'))) {
                newDates.dateFrom = moment(e.target.value).format('YYYY-MM-DD');
                newDates.dateTo = moment(e.target.value)
                    .add(diff, 'days')
                    .format('YYYY-MM-DD');
            } else if (!!e.target.value) {
                newDates.dateFrom = e.target.value;
            }
        } else {
            if (moment(e.target.value).isBefore(dateFrom)) {
                newDates.dateTo = moment(e.target.value).format('YYYY-MM-DD');
                newDates.dateFrom = moment(e.target.value)
                    .subtract(diff, 'days')
                    .format('YYYY-MM-DD');
            } else if (moment(e.target.value).isAfter(moment(dateFrom).add(7, 'days'))) {
                newDates.dateTo = moment(e.target.value).format('YYYY-MM-DD');
                newDates.dateFrom = moment(e.target.value)
                    .subtract(diff, 'days')
                    .format('YYYY-MM-DD');
            } else if (!!e.target.value) {
                newDates.dateTo = e.target.value;
            }
        }
        sessionStorage.setItem('catering', JSON.stringify({ date: moment(newDates.dateFrom).format('YYYYMMDD') }));

        this.setState(newDates);
    };

    render() {
        const { classes } = this.props;
        const { dateFrom, dateTo } = this.state;

        return (
            <Fragment>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                            <div>
                                <span>Catering</span>
                                <Hidden smUp>
                                    <PrintContent
                                        pageStyle={
                                            '@media print { body { -webkit-print-color-adjust: exact; color-adjust: exact; } }'
                                        }
                                        trigger={() => (
                                            <AltButton
                                                className={classes.headerButton}
                                                style={{ padding: 12, minWidth: 'unset' }}
                                            >
                                                <PrintIcon />
                                            </AltButton>
                                        )}
                                        content={() => this.formRef}
                                    />
                                </Hidden>
                            </div>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4} className={classes.calendarFieldsWrapper}>
                        <InlineField>
                            <DatePickerField
                                label={'From'}
                                value={dateFrom}
                                name="dateFrom"
                                onChange={this.dateChange}
                            />

                            <DatePickerField label={'Until'} value={dateTo} name="dateTo" onChange={this.dateChange} />
                        </InlineField>
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={12} sm={4}>
                            <PrintContent
                                pageStyle={
                                    '@media print { body { -webkit-print-color-adjust: exact; color-adjust: exact; } }'
                                }
                                trigger={() => (
                                    <AltButton className={classes.headerButton}>
                                        <PrintIcon /> Print
                                    </AltButton>
                                )}
                                content={() => this.formRef}
                            />
                        </Grid>
                    </Hidden>
                </Grid>
                {this.renderReport()}
            </Fragment>
        );
    }

    renderReport() {
        const { dateFrom, dateTo } = this.state;

        return (
            <Fragment>
                <Query
                    query={CateringReportFragment}
                    variables={{ from: dateFrom + ' 00:00:00', to: dateTo + ' 23:59:59' }}
                    ref={this.cateringQueryRef}
                >
                    {({ loading, error, data, networkStatus }) => {
                        if (loading && networkStatus === 1) {
                            return (
                                <Fragment>
                                    <div>Loading data, please wait a moment...</div>
                                    <LinearProgress />
                                </Fragment>
                            );
                        }

                        if (error) {
                            return (
                                <Fragment>
                                    <div style={{ float: 'left' }}>
                                        <Spinner />
                                    </div>
                                    <div style={{ paddingLeft: '1.25rem', fontSize: '75%', opacity: 0.5 }}>
                                        {error ? error.toString() : 'loading...'}
                                    </div>
                                </Fragment>
                            );
                        }

                        const getDays = () => {
                            var arr = {};
                            var dt = dateFrom;
                            while (moment(dt) <= moment(dateTo)) {
                                arr[moment(dt).format('YYYY-MM-DD')] = [];
                                dt = moment(dt).add(1, 'd');
                            }
                            return arr;
                        };
                        let daylist = getDays();
                        daylist = this.addUniqueDaysToDays(data, daylist);
                        return this.renderDays(daylist, networkStatus);
                    }}
                </Query>
            </Fragment>
        );
    }

    addUniqueDaysToDays(data, daylist) {
        let filteredAppointments = data.readCateringStaffs.edges.filter(e => !!e.node);

        let filteredFuneralEvents = this.FilterByDate(data, 'readCalendarEvents', 'Start');

        //cycle through appointments and create unique days
        return this.getUniqueDates(
            daylist,
            filteredAppointments,
            filteredFuneralEvents.sort((a, b) => moment(a.Start) - moment(b.Start)),
            'Date'
        );
    }

    getUniqueDates(days, appointments, events, key) {
        let result = [];

        days.Appointments = appointments.map(e => e.node);

        events.forEach(function(item) {
            if (!item.Funeral) return;
            if (!(item[key] in days)) {
                days[item[key]] = [];
                result.push(days[item[key]]);
            }
            let tempDays = days[item[key]];
            if (!tempDays.FuneralEvents) {
                tempDays.FuneralEvents = [];
                tempDays.FuneralEvents.push(item);
            } else {
                tempDays.FuneralEvents.push(item);
            }
        });

        return days;
    }

    FilterByDate(data, node, dateObj) {
        const original = cloneDeep(data);
        flattenConnection(original, [node]);

        let datesTimesAdded = [];

        original[node].forEach(function(item, i) {
            let date = moment(item[dateObj]);
            if (!date.isValid()) return;
            item['Date'] = date.format('YYYY-MM-DD');
            item['Time'] = date.format('HH:mm:ss');
            datesTimesAdded.push(item);
        });

        return datesTimesAdded;
    }

    renderDays(data, networkStatus) {
        const { classes } = this.props;
        const { dateFrom, dateTo, showAll, showYear } = this.state;

        let days = [];

        for (let item in data) {
            days.push(item);
        }

        const hasApps = !!data.Appointments;

        const getFinYear = Number(moment(dateFrom).format('M')) > 6;
        const finYear = Number(moment(dateFrom).format('Y')) + Number(getFinYear);

        return (
            <Paper className={classes.paper} elevation={0}>
                {!!networkStatus && networkStatus !== 7 && (
                    <Fragment>
                        <div>Loading data, please wait a moment...</div>
                        <LinearProgress />
                    </Fragment>
                )}
                <div className={classes.rootContainer}>
                    <div ref={e => (this.formRef = e)} className={classes.root}>
                        <Grid container spacing={24} className={classes.print}>
                            <Grid item xs={12}>
                                <Typography variant="headline" align={'center'}>
                                    Catering Staff Usage
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <InlineField className="print-hide">
                                    <Checkbox
                                        checked={showYear}
                                        onClick={() => this.setState({ showYear: !showYear })}
                                        label="Show Financial Year"
                                    />
                                    <Checkbox
                                        checked={showAll}
                                        onClick={() => this.setState({ showAll: !showAll })}
                                        label="Show Retired Staff"
                                    />
                                </InlineField>
                                <Table
                                    columns={[
                                        <span>
                                            {!!showYear
                                                ? ` July ${finYear - 1} to June ${finYear}`
                                                : ` ${niceDateFromString(dateFrom)}${
                                                      dateTo !== dateFrom ? ` to ${niceDateFromString(dateTo)}` : ''
                                                  }`}
                                        </span>
                                    ]}
                                    columnspans={[4]}
                                >
                                    {this.renderAppointments(data, finYear)}
                                </Table>
                            </Grid>

                            {days.map((item, i, a) => {
                                const hasEvts = !!data[days[i]] && !!data[days[i]].FuneralEvents;
                                if (!moment(item).isValid()) return null;
                                return (
                                    <Fragment key={i}>
                                        <div
                                            className={'print-hide'}
                                            style={{
                                                margin: 32,
                                                height: 1,
                                                background: '#e6e6e6'
                                            }}
                                        />

                                        <Grid item xs={12} className={classes.header}>
                                            <div className={i === 0 ? '' : 'not-page-break'} />
                                            <Typography variant="headline">
                                                Daily Catering Assignments for {moment(item).format('dddd[,] LL')}
                                            </Typography>
                                        </Grid>

                                        {/* display funeral events if they exist */}
                                        {hasEvts && this.renderFuneralEvents(data[days[i]])}

                                        {(!hasApps || !hasEvts) && (
                                            <Grid item xs={12}>
                                                {/*{!hasApps && (
                                                    <Typography align={'center'}>
                                                        There are no appointments on this day.
                                                    </Typography>
                                                )}*/}

                                                {!hasEvts && (
                                                    <Typography align={'center'}>
                                                        There are no events on this day.
                                                    </Typography>
                                                )}
                                            </Grid>
                                        )}
                                    </Fragment>
                                );
                            })}
                        </Grid>
                    </div>
                </div>
            </Paper>
        );
    }

    renderAppointments(data, finYear) {
        if (!data.Appointments) return <p>There is no data for this period.</p>;
        const { classes } = this.props;
        const { dateFrom, dateTo, showAll, showYear } = this.state;

        const rangeStart = showYear ? moment(finYear - 1 + '-06-30') : moment(dateFrom);
        const rangeUntil = showYear ? moment(finYear + '-07-01') : moment(dateTo);

        const assignments = data.Appointments.map(item => {
            if (!!item.NotCurrent && !showAll) return null;
            const points = item.CateringStaffPoints.find(e => Number(e.Year) === Number(finYear));
            const dates = [].concat(
                item.CateringStaffAllocation.filter(
                    e =>
                        moment(e.End) > moment(finYear - 1 + '-06-30') &&
                        moment(e.Start) < moment(finYear + '-07-01') &&
                        !e.Availability
                ).map(e => {
                    return {
                        begin: moment(e.Start).format('YYYY-MM-DD'),
                        until: moment(e.End).format('YYYY-MM-DD')
                    };
                }),
                item.CateringStaffDaysoff.filter(
                    e => moment(e.Date) > moment(finYear - 1 + '-06-30') && moment(e.Date) < moment(finYear + '-07-01')
                ).map(e => {
                    return {
                        begin: moment(e.Date).format('YYYY-MM-DD'),
                        until: moment(e.Date).format('YYYY-MM-DD')
                    };
                }),
                item.CateringStaffAnnualLeave.filter(
                    e =>
                        moment(e.EndDate) > moment(finYear - 1 + '-06-30') &&
                        moment(e.StartDate) < moment(finYear + '-07-01')
                ).map(e => {
                    return {
                        begin: moment(e.StartDate).format('YYYY-MM-DD'),
                        until: moment(e.EndDate).format('YYYY-MM-DD')
                    };
                })
            );
            if (!points && dates.length < 1) return null;
            return {
                ID: item.ID,
                Name: item.Name,
                NotCurrent: !!item.NotCurrent,
                Contacts: prettyPhone(joinDefined([item.Phone, item.Mobile], '/'), 0),
                Points: !!points ? Number(points.Points) : 0,
                Dates: dates
                    .sort((a, b) => (moment(a.begin).isBefore(moment(b.begin)) ? -1 : 1))
                    .filter((item, pos, arr) => {
                        // reduce duplicates
                        if (arr.length === 1) return true;
                        if (item.begin !== item.until) return true;
                        if (arr[pos + 1] && item.begin !== arr[pos + 1].begin) return true;
                        if (!arr[pos + 1] && item.begin !== arr[pos - 1].begin) return true;
                        return false;
                    })
                    .filter(
                        d => moment(d.begin).isBefore(rangeUntil, 'day') && moment(d.until).isAfter(rangeStart, 'day')
                    )
                    .map((e, i) => {
                        const dates =
                            moment(e.begin).format('ddd DD/MM/YYYY') +
                            (e.begin !== e.until ? ' to ' + moment(e.until).format('ddd DD/MM/YYYY') : '');
                        return moment(e.until).isAfter() ? <strong key={i}>{dates}</strong> : dates;
                    })
            };
        }).filter(e => !!e);

        if (assignments.length < 1)
            return (
                <TableRow pad className={'page-break-avoid'}>
                    <TableCell colSpan={4} centred>
                        <Typography>There are no staff records for this period.</Typography>
                    </TableCell>
                </TableRow>
            );

        return (
            <Fragment>
                <TableSecondaryHeaderRow>
                    <TableHeaderCell>
                        <span>Staff</span>
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <span>Phone</span>
                    </TableHeaderCell>
                    <TableHeaderCell className={classes.colXs}>
                        <span>Points</span>
                    </TableHeaderCell>
                    <TableHeaderCell className={classes.colMd}>
                        <span>Dates Unavailable</span>
                    </TableHeaderCell>
                </TableSecondaryHeaderRow>

                {assignments
                    .sort((a, b) => ((a.Points === b.Points ? a.Name > b.Name : a.Points < b.Points) ? 1 : -1))
                    .map((item, j) => {
                        return (
                            <TableRow key={j} pad className={'page-break-avoid'}>
                                <TableCell>
                                    <span>
                                        {item.NotCurrent && 'RETIRED '}
                                        {item.Name}
                                    </span>
                                </TableCell>
                                <TableCell>
                                    <span className={classes.notes}>{item.Contacts}</span>
                                </TableCell>
                                <TableCell>
                                    <span>{item.Points}</span>
                                </TableCell>
                                <TableCell>
                                    <span>
                                        {item.Dates.map((date, i) => (
                                            <span key={i}>
                                                {i ? ', ' : ''}
                                                {date}
                                            </span>
                                        ))}
                                    </span>
                                </TableCell>
                            </TableRow>
                        );
                    })}
            </Fragment>
        );
    }

    renderFuneralEvents(list) {
        if (isNullOrUndefined(list.FuneralEvents)) return null;
        const { classes } = this.props;

        const funeralEvents = [];
        const funServices = list.FuneralEvents.filter(item => {
            return item.__typename === 'FuneralPlaceOfService';
        });

        const pushViewing = [];
        list.FuneralEvents.forEach((item, i, a) => {
            let keepFun = true;

            if (item.__typename === 'FuneralPlaceOfService') {
                // keep the fun!
                const funViews = pushViewing.filter(e => e.ID === item.Funeral.ID);
                if (!!funViews && funViews.length > 0) {
                    funViews.forEach(viewing => {
                        funeralEvents.push(viewing.view);
                    });
                }
            } else {
                const funService = funServices.find(e => e.Funeral.ID === item.Funeral.ID);

                // Date of service = date of refreshment, combine
                if (funService && item.__typename === 'RefreshmentsVenue') {
                    keepFun = false;
                } else if (
                    funService &&
                    item.__typename === 'FuneralDisposal' &&
                    moment(item.Start).diff(funService.Start, 'hours') < 3 &&
                    moment(item.Start).diff(funService.Start, 'hours') > 0
                ) {
                    // If date of service = date of committal, time of committal<3 hrs after time of service then combine into 1 event on the runsheet.
                    // Time of committal ≥ 3hrs after time of service, or before time of service or different date – show separately
                    keepFun = false;
                } else if (
                    !funService &&
                    item.__typename === 'FuneralDisposal' &&
                    !!item.Funeral.PlaceOfService &&
                    item.Funeral.PlaceOfService.Type === 'Graveside'
                ) {
                    // If Graveside service, inject a separate/fake service event
                    const fakey = {
                        ...item.Funeral.PlaceOfService,
                        ClassName: 'FuneralManagerFuneralPlaceOfService',
                        StaffAllocations: item.StaffAllocations,
                        Funeral: item.Funeral,
                        Start: item.Start,
                        End: item.End
                    };
                    fakey.Funeral.PlaceOfService.Start = item.Start;
                    fakey.Funeral.PlaceOfService.End = item.End;
                    funeralEvents.push(fakey);
                } else if (funService && item.__typename === 'FuneralPlaceOfViewing') {
                    // If viewing, hide or move the event to appear next to service (ie. move out of sequence)
                    // Date of viewing >1hrs before time of service, or different date – show separately
                    if (moment(item.Start).diff(funService.Start, 'hours') > 1) {
                        keepFun = false;
                    }

                    // If date of viewing = date of service, time of viewing ≤1 hrs before time of service then make sure Viewing event is shown on runsheet immediately above Funeral Service event
                    if (moment(item.Start).diff(funService.Start, 'hours') <= 1) {
                        keepFun = false;
                        pushViewing.push({ ID: item.Funeral.ID, view: item });
                    }
                }
            }
            if (!!keepFun) funeralEvents.push(item);
        });

        return (
            <Fragment>
                {funeralEvents.map((item, i) => (
                    <Grid item xs={12} key={i}>
                        <Table className={'page-break-avoid'}>
                            <TableHeaderRow>
                                <TableHeaderCell colSpan="2">
                                    {this.renderEventTitle(item)}
                                    {this.renderBrand(item)}
                                </TableHeaderCell>
                            </TableHeaderRow>
                            <TableSecondaryHeaderRow>
                                <TableHeaderCell className={classes.sideColumn}>
                                    <span>Allocated Staff</span>
                                </TableHeaderCell>

                                <TableHeaderCell>Event Instructions</TableHeaderCell>
                            </TableSecondaryHeaderRow>
                            {this.renderEvents(item)}
                        </Table>
                    </Grid>
                ))}
            </Fragment>
        );
    }

    renderBrand(item) {
        if (!isNullOrUndefined(item.Funeral.LegacyKey)) {
            let brand = getOfficeFromKey(item.Funeral.LegacyKey);
            return (
                <span className={this.props.classes.brandField}>
                    <LinkButton
                        title={'View Record'}
                        classes={{
                            enabled: this.props.classes.niceLink
                        }}
                        href={this.viewRecord(item, { type: this.renderEventType(item) }, false)}
                        onClick={e => this.viewRecord(item, { type: this.renderEventType(item) })}
                        text={item.Funeral.LegacyKey}
                    />{' '}
                    | {(brand && brand.label) || '?'}
                </span>
            );
        } else {
            return null;
        }
    }

    renderEvents(item) {
        if (item.ClassName === 'FuneralManagerRefreshmentsVenue') return this.renderFuneralEvent(item);

        console.error('unknown funeral event type', item.ClassName);

        return null;
    }

    renderFuneralEvent(item) {
        const { classes } = this.props;

        return (
            <TableRow pad>
                <TableCell className={classes.sideColumn}>
                    {this.renderStaffList(item.CateringStaffAllocations)}
                    <Separator />
                    {this.renderFuneralTitle(item)}
                </TableCell>
                <TableCell className={classes.packedIn}>{this.renderUsageDate(item, classes)}</TableCell>
            </TableRow>
        );
    }

    renderUsageDate(item, classes) {
        const applicantName = isRelatedObjectDefined(item.Funeral.Informant)
            ? joinDefined([item.Funeral.Informant.InformantsName, item.Funeral.Informant.RelationshipToDeceased], ' - ')
            : false;
        const hasBookings = (item.Funeral.Caterers && item.Funeral.Caterers.filter(e => !!e && !e.Cancelled)) || [];
        const hasMenu =
            (hasBookings.length > 0 &&
                hasBookings.map(e => {
                    const item = { ...e };
                    item.BookingItems =
                        (!e.BookingItems.edges && e.BookingItems.length > 0 && e.BookingItems) ||
                        (e.BookingItems.edges.length > 0 && e.BookingItems.edges.map(ee => ee.node)) ||
                        [];
                    return item;
                })) ||
            false;

        const menus =
            !!hasMenu &&
            hasMenu.map(
                m =>
                    `By ${m.Contact.Name} ${!m.Confirmed ? ' NOT CONFIRMED: ' : ': '}` +
                    m.BookingItems.map(e => !!e && ` ${e.Quantity}x ${e.Title} ${e.Comment ? ` (${e.Comment})` : ''}`)
            );

        return (
            <div>
                <span className={classes.fullWidth}>
                    <strong>LOCATION: </strong>
                    {item.LocationFlattened || <span className={classes.notSpecified}>(not set)</span>}
                    {item.LocationFlattened && (item.Confirmed ? '' : ' NOT CONFIRMED')}.
                </span>
                <span className={classes.fullWidth}>
                    <strong>WHEN: </strong>
                    {niceDateTimeFromString(item.Start)} until {niceTimeFromString(item.End)}.
                </span>
                <span className={classes.fullWidth}>
                    <strong>NUMBER OF GUESTS: </strong>
                    {item.NumberOfPeople}.
                </span>
                {hasMenu && (
                    <span className={classes.fullWidth}>
                        <strong>MENU: </strong>
                        {menus}
                    </span>
                )}
                <span className={classes.fullWidth}>
                    <strong>APPLICANT: </strong>
                    {applicantName || <span className={classes.notSpecified}>(not set)</span>}.
                </span>
                <span className={classes.fullWidth}>
                    <strong>COMMENTS: </strong>
                    {item.Comment || <span className={classes.notSpecified}>(none)</span>}
                </span>
            </div>
        );
    }

    renderStaffList(staffAllocations) {
        const { classes } = this.props;

        const trimmed = staffAllocations.filter(e => !!e); //.sort((a, b) => (a.Member.FirstName < b.Member.FirstName ? -1 : 1));
        if (trimmed.length === 0)
            return (
                <p>
                    <span className={classes.notSpecified}>No Allocations.</span>
                </p>
            );

        return (
            <Fragment>
                {trimmed.map((item, index) => (
                    <p key={index}>
                        {item.CateringStaff.Name}
                        <small>
                            <br />
                            {!!item.Availability && (
                                <span className={classes.forcedLine}>
                                    {niceTimeFromString(item.Start)} - {niceTimeFromString(item.End)}
                                    <br />
                                </span>
                            )}
                            <span className={classes.forcedLine}>
                                {!!item.Confirmed ? '' : !!item.Availability ? 'NOT CONFIRMED' : 'NOT AVAILABLE'}
                            </span>
                        </small>
                    </p>
                ))}
            </Fragment>
        );
    }

    renderEventTitle(item) {
        let name = '';
        name = joinDefined(
            [item.Funeral.FirstName, item.Funeral.MiddleName, ('' + item.Funeral.Surname).toUpperCase()],
            ' '
        );
        return (
            this.renderEventType(item).toUpperCase() +
            ': ' +
            moment(item.Start).format('h:mma') +
            ` - ${name || '(no name)'}`
        );
    }

    renderEventType(item) {
        let type = '';

        if (item.ClassName === 'FuneralManagerFuneralDisposal') {
            type = item.Funeral.Disposal.CrematedOrBuried === 'Cremated' ? 'Cremation' : 'Burial';
        } else if (item.ClassName === 'FuneralManagerFuneralPlaceOfViewing') {
            type = 'Viewing';
        } else if (item.ClassName === 'FuneralManagerFuneralPlaceOfService') {
            type = 'Service';
        } else if (item.ClassName === 'FuneralManagerRefreshmentsVenue') {
            type = 'Refreshments';
        } else {
            type = 'Other';
        }

        return type;
    }

    renderFuneralTitle(item) {
        let arrangers = [];
        let coordinators = [];
        let conductors = [];

        if (!isNullOrUndefined(item.Funeral.Arrangers)) {
            arrangers = joinDefined(
                item.Funeral.Arrangers.filter(item => !isNullOrUndefined(item.Member))
                    .map(item => joinDefined([item.Member.FirstName, item.Member.Surname], ' ').trim())
                    .filter(o => o),
                ', '
            );
        }

        if (!isNullOrUndefined(item.Funeral.Coordinators)) {
            coordinators = joinDefined(
                item.Funeral.Coordinators.filter(item => !isNullOrUndefined(item.Member))
                    .map(item => joinDefined([item.Member.FirstName, item.Member.Surname], ' ').trim())
                    .filter(o => o),
                ', '
            );
        }

        if (!isNullOrUndefined(item.Funeral.Conductors)) {
            conductors = joinDefined(
                item.Funeral.Conductors.filter(item => !isNullOrUndefined(item.Member))
                    .map(item => joinDefined([item.Member.FirstName, item.Member.Surname], ' ').trim())
                    .filter(o => o),
                ', '
            );
        }
        const noStaff = <span className={this.props.classes.notSpecified}>None</span>;
        return (
            <>
                <p>
                    <small>
                        Conductors:
                        <br />
                    </small>
                    {conductors.length ? conductors : noStaff}.
                </p>
                <p>
                    <small>
                        Arrangers:
                        <br />
                    </small>
                    {arrangers.length ? arrangers : noStaff}.
                </p>
                <p>
                    <small>
                        Coordinators:
                        <br />
                    </small>
                    {coordinators.length > 0 ? coordinators : noStaff}.
                </p>
            </>
        );
    }

    viewRecord = (event, category, pushURL = true) => {
        const url = '/funeral/' + event.Funeral.LegacyKey + '/' + event.Funeral.ID;
        const tab = category.type === 'Refreshments' ? 'optionals' : 'funeral-details';
        if (pushURL) this.props.history.push(url + '/' + tab);
        return url + '/' + tab;
    };
}

const styles = ({ funeralHome, palette }) => ({
    paper: {
        /*padding: spacing.unit * 3,*/
        marginTop: 32
    },
    packedIn: {
        lineHeight: 1.5
    },
    forcedLine: {
        display: 'block'
    },
    pageTitle: {
        color: palette.contentForeground[funeralHome]
    },
    fullWidth: {
        marginRight: 6,
        display: 'block',
        '&:not(:last-child)': {
            marginBottom: 6
        }
    },
    fullWidthDiv: {
        marginRight: 6
    },
    border: {
        border: '1px solid #aa55aa'
    },
    sideColumn: {
        width: '8.7rem',
        '& > p:first-of-type': {
            marginTop: 0
        },
        '& > p:last-of-type': {
            marginBottom: 0
        }
    },
    extraColumn: {
        width: '13.3rem'
    },
    header: {
        textAlign: 'center'
    },
    headerButton: {
        float: 'right'
    },
    brandField: {
        float: 'right'
    },
    calendarFieldsWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    notSpecified: {
        fontStyle: 'italic',
        color: '#777'
    },
    niceLink: {
        '& > span': {
            color: '#ffffff'
        },
        '& > span:hover': {
            color: '#ffffffAA'
        }
    },
    notes: {
        // fontStyle: 'italic',
        // color: '#777',
        '& a': {
            color: 'inherit'
        }
    },
    colMd: {
        width: '50%'
    },
    colXs: {
        width: 1
    }
});

export default withErrorBoundary(
    compose(
        withSnackbarMessage,
        withStyles(theme => ({ ...styles(theme), ...formStyles(theme) }))
    )(CateringReport),
    { fallback: ErrorFallback }
);
