import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { ADDRESS_BOOK_CATEGORIES } from '../../page/funeral/funeralConstants';
import GqlAutocomplete, { getValueById } from './GqlAutocomplete';
import { isContactDefined } from '../../util/bookable';
import AddressBookModal from './AddressBookModal';
import { withStyles } from '@material-ui/core';
import { OutlineButton } from './PrimaryButton';
import { getUtilitiesClient } from '../../apollo';

class AddressBookAutocomplete extends Component {
    state = {
        showAddressBookModal: false
    };

    render() {
        const { labelFieldFunc, classes, onAddNewContact, noAddNew = false, disabled, ...props } = this.props;
        const categories = props.categories || ADDRESS_BOOK_CATEGORIES;
        const filteredCategories = categories && categories.length > 0 ? categories.map(c => c.key) : null;
        const category = categories.length > 0 ? categories[0] : null;
        const { showAddressBookModal } = this.state;

        return (
            <Fragment>
                <AddressBookModal
                    onClose={contact => this.onCloseAddressBookModal(contact, props.name)}
                    open={showAddressBookModal}
                    category={category}
                />

                <div className={`${classes.root} custom-width`}>
                    <GqlAutocomplete
                        disabled={disabled}
                        readOneQuery={readOneAddressBook}
                        readAllQuery={readAllAddressBooks}
                        form={props.form}
                        {...props}
                        categories={filteredCategories}
                        labelFieldFunc={null}
                        extraLabelField={['AddressLine1', 'Suburb', 'State']}
                        queryClient={getUtilitiesClient()}
                        addNewButton={
                            !noAddNew && (
                                <OutlineButton
                                    title="Add a new Contact to our Address Book"
                                    className={classes.addNewButton}
                                    text="Add New&hellip;"
                                    onClick={() => this.setState({ showAddressBookModal: true })}
                                    disabled={props.disabled}
                                />
                            )
                        }
                    />
                </div>
            </Fragment>
        );
    }

    onCloseAddressBookModal(contact) {
        if (contact) {
            const { form, name, onAddNewContact } = this.props;
            if (form) {
                if (!!form.setField) {
                    form.setField({ [name]: contact });
                } else if (!!form.setState) {
                    form.setState({ [name]: contact });
                } else {
                    console.warn('Form does not provide setField or setState methods. Is it even form object?');
                }
            } else {
                console.warn('Form is null, it is unexpected!');
            }

            if (onAddNewContact) onAddNewContact(contact);
        }
        this.setState({ showAddressBookModal: false });
    }
}

const readAllAddressBooks = gql`
    query ReadAddressBooks($id: String, $contains: String, $filter: [String], $limit: Int, $key: String) {
        readAddressBooks(
            id: $id
            contains: $contains
            limit: $limit
            filterCategories: $filter
            activeOnly: true
            key: $key
        ) {
            edges {
                node {
                    ID
                    LegacyKey
                    Name
                    Contact
                    Email
                    Phone
                    Fax
                    Mobile
                    State
                    Suburb
                    Postcode
                    AddressBookCategory
                    AddressLine1
                    AddressLine2
                    Notes
                }
            }
            pageInfo {
                totalCount
            }
        }
    }
`;

const readOneAddressBook = gql`
    query ReadOneAddressBook($id: ID!) {
        readOneAddressBook(ID: $id) {
            ID
            LegacyKey
            Name
            Contact
            Email
            Phone
            Fax
            Mobile
            State
            Suburb
            Postcode
            AddressBookCategory
            AddressLine1
            AddressLine2
        }
    }
`;

const styles = () => ({
    root: {
        display: 'inline-flex',
        width: '100%',
        '& button': {
            width: 0,
            padding: 0,
            opacity: 0,
            overflow: 'hidden',
            transition: 'opacity 0.4s, width 0.5s ease-out 0s'
        },
        '&:hover button': {
            width: 80,
            opacity: 1,
            transition: 'opacity 0.25s'
        }
    },
    addNewButton: {
        display: 'inline-block',
        margin: '0 -4px 0 0',
        borderRadius: '20px 4px 4px 20px',
        height: 28,
        minWidth: 'unset',
        whiteSpace: 'pre',
        padding: '0 8px',
        textAlign: 'center',
        '& > span': {
            fontSize: '0.75em'
        }
    }
});

export const getAddressBookById = id => {
    return getValueById(getUtilitiesClient(), id, readOneAddressBook, null);
};

export default withStyles(styles)(AddressBookAutocomplete);

export const getLabelWithSuburb = addressBook => {
    return addressBook.Suburb ? `${addressBook.Name} (${addressBook.Suburb} ${addressBook.State})` : addressBook.Name;
};

export const getLabelWithID = addressBook => {
    return isContactDefined(addressBook) ? `${addressBook.ID}: ${addressBook.Name}` : null;
};
