import React from 'react';
import gql from 'graphql-tag';
import GqlAutocomplete from './GqlAutocomplete';
import { withStyles } from '@material-ui/core';
import { getUtilitiesClient } from '../../apollo';
import { ProductPriceOverrides, VariationPriceOverrides } from '../../page/fragments/Product';

const LineItemAutoComplete = ({
    category,
    className,
    name,
    label = 'Add Line Item...',
    variations = false,
    onSelect,
    ...props
}) => {
    const convertResults = data => {
        if (!data || !data.readProducts) return null;
        const result = [];
        for (let x = 0; x < data.readProducts.edges.length; x++) {
            const Product = data.readProducts.edges[x].node;
            if (!!variations && !!Product.Variations && Product.Variations.edges.length) {
                for (let y = 0; y < Product.Variations.edges.length; y++) {
                    const Variation = Product.Variations.edges[y].node;
                    const Code = Product.InternalItemID + ' | ' + Variation.InternalItemID;
                    const item = {
                        ID: Code,
                        Name:
                            Product.Title +
                            ' | ' +
                            (Variation.ShortDescription ? Variation.ShortDescription : Variation.InternalItemID),
                        Product: { ...Product },
                        Variation
                    };
                    delete item.Product.Variations;
                    result.push(item);
                }
            } else {
                const Code = Product.InternalItemID;
                const item = {
                    ID: Code,
                    Name: Product.Title,
                    Product: { ...Product }
                };
                delete item.Product.Variations;
                result.push(item);
            }
        }
        return result;
    };

    const selectResult = (e, selected) => {
        const item = {};
        item.ID = null;
        item.Qty = 1;
        item.Product = selected.Product;
        item.Variation = selected.Variation;
        if (!!onSelect) return onSelect({ ...item });
        return { ...item };
    };

    return (
        <GqlAutocomplete
            queryClient={getUtilitiesClient()}
            extraLabelFunc={result => <small style={{ float: 'left', paddingRight: 12 }}>{result.ID}</small>}
            convertResults={convertResults}
            label={label}
            readAllQuery={readAllItems}
            clearOnSelect={true}
            onSelect={selectResult}
            {...props}
        />
    );
};

const readAllItems = gql`
    ${ProductPriceOverrides}
    ${VariationPriceOverrides}
    query ReadItems($contains: String) {
        readProducts(contains: $contains, purchase: true) {
            edges {
                node {
                    ID
                    InternalItemID
                    Title
                    BasePrice
                    GST
                    ...ProductPriceOverrides
                    Variations {
                        edges {
                            node {
                                ID
                                InternalItemID
                                ShortDescription
                                GST
                                Price
                                ...VariationPriceOverrides
                            }
                        }
                    }
                }
            }
            pageInfo {
                totalCount
            }
        }
    }
`;

const styles = () => {};

export default withStyles(styles)(LineItemAutoComplete);
