export const REGISTRATION_STATUS_OPTIONS = [
    { label: 'Not Ready', value: '1' },
    { label: 'Hold', value: '2' },
    { label: 'Ready', value: '3' },
    { label: 'Processed', value: '4' }
];

export const TabFieldMapping = {
    Surname: { label: 'Deceased', link: '/funeral/:key/:id/deceased' },
    FirstName: { label: 'Deceased', link: '/funeral/:key/:id/deceased' },
    FamilyNameAtBirth: { label: 'Deceased', link: '/funeral/:key/:id/deceased' },
    DateOfDeathType: { label: 'Deceased', link: '/funeral/:key/:id/deceased' },
    DeathOccuredInHospital: { label: 'Deceased', link: '/funeral/:key/:id/deceased' },
    ResidentialAddress1: { label: 'Deceased', link: '/funeral/:key/:id/deceased' },
    ResidentialTown: { label: 'Deceased', link: '/funeral/:key/:id/deceased' },
    ResidentialState: { label: 'Deceased', link: '/funeral/:key/:id/deceased' },
    ResidentialCountry: { label: 'Deceased', link: '/funeral/:key/:id/deceased' },
    PostCode: { label: 'Deceased', link: '/funeral/:key/:id/deceased' },
    PlaceOfBirthCountry: { label: 'Deceased', link: '/funeral/:key/:id/deceased' },
    PlaceOfBirthState: { label: 'Deceased', link: '/funeral/:key/:id/deceased' },
    PlaceOfBirth1: { label: 'Deceased', link: '/funeral/:key/:id/deceased' }
};

export const BDM_STATUS_DONE = ['FD Import', 'Compliant', 'Complete', 'Submitted', 'Successful'];
