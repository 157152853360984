import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import TextField from '../form/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '../form/Grid';
import { CloseIcon, EnvelopeIcon } from '../IconIndex';
import cloneDeep from 'lodash.clonedeep';
import { getClient } from '../../apollo';
import gql from 'graphql-tag';
import Label from '../../component/form/Label';
import AddressAutocomplete from '../../component/form/AddressAutocomplete';
import { GST, ProductConfig } from '../../util/products';
import { isNullOrUndefined } from '../../util/objects';
import { joinDefined, prettyPrice } from '../../util/strings';
import { niceTimeFromString } from '../../util/date';
import moment from 'moment';
import { isRelatedObjectDefined } from '../../util/bookable';
import { createForm } from '../../util/form';
import { renderToString } from 'react-dom/server';
import ButtonWrapper from '../../page/workQueue2/extras/Button';
import { Hidden } from '@material-ui/core';

class RequestBookingModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            sendingEmail: false,
            date: moment(props.booking.Required).format('YYYY-MM-DD'),
            booking: null,
            address: null
        };
    }

    static getDerivedStateFromProps(nextProps, oldState) {
        if (oldState.open === false && nextProps.open === true) {
            const booking = nextProps.booking ? cloneDeep(nextProps.booking) : {};
            const address = {};

            const { hasVenue } = nextProps;
            if (hasVenue && isRelatedObjectDefined(hasVenue)) {
                booking.DeliveryDate = hasVenue.Date;
                booking.DeliveryTime = hasVenue.Time;
                if (isRelatedObjectDefined(hasVenue, 'Location')) {
                    booking.DeliveryAddress = joinDefined(
                        [
                            hasVenue.Location.AddressLine1,
                            hasVenue.Location.AddressLine2,
                            hasVenue.Location.Suburb,
                            hasVenue.Location.State !== 'NSW' && hasVenue.Location.State
                        ],
                        ', '
                    );
                    address.AddressLine1 = hasVenue.Location.AddressLine1;
                    address.AddressLine2 = hasVenue.Location.AddressLine2;
                    address.Suburb = hasVenue.Location.Suburb;
                    address.State = hasVenue.Location.State !== 'NSW' && hasVenue.Location.State;
                }
            }

            return { booking, address, open: true };
        } else if (nextProps.open === false && oldState.open === true) {
            return { open: false };
        }
        return null;
    }

    render() {
        const { classes, className } = this.props;
        const { booking, open } = this.state;
        const form = createForm(this, this.state);
        return (
            <Dialog
                classes={{ paper: cx(className, classes.root, 'dialog-modal modal') }}
                open={open}
                onClose={this.onClose}
                aria-labelledby={'RequestBookingModal'}
            >
                <form onSubmit={e => this.onSubmit(e)}>{booking && this.renderForm(form)}</form>
            </Dialog>
        );
    }

    renderForm(form) {
        const { sendingEmail, address, booking } = this.state;
        const { classes, isOrder, label, contact, funeralInfo } = this.props;
        const actionType = isOrder ? 'Order' : 'Booking';

        const displayBookingItems = booking.BookingItems && !!booking.BookingItems.length && (
            <Fragment>
                <tr style={{ verticalAlign: 'top' }}>
                    <td>
                        <Label>Items:</Label>
                    </td>
                    <td>
                        <table style={{ borderSpacing: 0, width: '100%' }}>
                            <tbody>
                                <tr style={{ verticalAlign: 'top' }}>
                                    <td>{booking.BookingItems.map((item, idx) => renderProductItem(item, idx))}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr style={{ verticalAlign: 'top' }}>
                    <td>
                        <Label>Total:</Label>
                    </td>
                    <td>
                        {prettyPrice(booking.BookingItems.reduce((acc, obj) => acc + gstIncPrice(obj), 0))} (includes
                        GST)
                    </td>
                </tr>
            </Fragment>
        );

        return (
            <Fragment>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        overflow: 'hidden',
                        pointerEvents: 'none'
                    }}
                >
                    <EnvelopeIcon
                        style={{
                            opacity: 0.025,
                            width: 300,
                            height: 300,
                            position: 'absolute',
                            right: -24,
                            top: 124,
                            transform: 'rotate(20deg)',
                            zIndex: 0
                        }}
                    />
                </div>
                <DialogTitle className={classes.diagHeader}>
                    <Hidden xsDown>
                        <EnvelopeIcon className={classes.diagHeaderIcon} />
                        <div className={classes.diagHeaderTitle}>Funeral {funeralInfo.LegacyKey}</div>
                        <div className={classes.diagHeaderSubtitle}>
                            {actionType} Request for {label || 'Supplier'}
                        </div>
                    </Hidden>
                    <Hidden smUp>
                        <div className={classes.diagHeaderTitle}>
                            {actionType} Request for {label || 'Supplier'}
                        </div>
                    </Hidden>
                </DialogTitle>

                <DialogContent className={classes.content}>
                    <Grid container spacing={8} pc={1} style={{ margin: 0 }}>
                        <Grid item xs={12}>
                            <table style={{ borderSpacing: '0 6px', width: '100%' }}>
                                <tbody>
                                    <tr style={{ verticalAlign: 'top' }}>
                                        <td style={{ width: 150 }}>
                                            <Label>{isOrder ? 'Supplier' : 'Provider'}:</Label>
                                        </td>
                                        <td style={{ width: '100%' }}>{contact.Name}</td>
                                    </tr>
                                    <tr style={{ verticalAlign: 'top' }}>
                                        <td>
                                            <Label>Send&nbsp;To:</Label>
                                        </td>
                                        <td>
                                            <span style={{ wordBreak: 'break-all' }}>{contact.Email}</span>
                                        </td>
                                    </tr>
                                    {displayBookingItems}
                                </tbody>
                            </table>
                        </Grid>
                        <Grid item xs={12}>
                            <Label
                                text={isOrder ? 'Delivery Address' : 'Event Address'}
                                htmlFor="booking.DeliveryAddress"
                            />

                            <AddressAutocomplete
                                id="DeliveryAddress"
                                placeholder="Search for a street address..."
                                form={form}
                                componentFields={{
                                    line1: 'address.AddressLine1',
                                    line2: 'address.AddressLine2',
                                    city: 'address.Suburb',
                                    state: 'address.State'
                                }}
                                name={address}
                                value={booking.DeliveryAddress}
                                allowCustomAddress={true}
                                onSelect={e => this.setField('DeliveryAddress', e)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={'Date Required'}
                                type="date"
                                placeholder="Enter delivery or event date..."
                                value={booking.DeliveryDate}
                                onChange={e => this.setField('DeliveryDate', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={'Time Required'}
                                type="time"
                                placeholder="Enter delivery or event time..."
                                value={booking.DeliveryTime}
                                onChange={e => this.setField('DeliveryTime', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={'Additional Instructions'}
                                multiline={true}
                                rows={8}
                                placeholder="Enter any additional instructions here..."
                                value={booking.Notes}
                                onChange={e => this.setField('Notes', e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions className={cx(classes.diagActions, 'modal-footer', 'paper')}>
                    <div>
                        <ButtonWrapper variant="secondary" onClick={this.onCancel} startIcon={<CloseIcon />}>
                            Cancel
                        </ButtonWrapper>
                    </div>
                    <ButtonWrapper
                        variant="confirmation"
                        type="submit"
                        disabled={!!sendingEmail}
                        startIcon={<EnvelopeIcon />}
                    >
                        {!!sendingEmail ? 'Sending...' : 'Send Email'}
                    </ButtonWrapper>
                </DialogActions>
            </Fragment>
        );
    }

    setField(propertyName, value) {
        const { booking } = this.state;
        booking[propertyName] = value;
        this.setState({ booking });
    }

    onCancel = reload => {
        this.props.onClose(reload);
        this.clearState();
    };

    clearState() {
        this.setState({ booking: null, sendingEmail: false, address: null });
    }

    onSubmit(e) {
        e.preventDefault();

        const { contact } = this.props;
        const { booking, sendingEmail } = this.state;

        if (!!sendingEmail) return null;
        else this.setState({ sendingEmail: true });

        const that = this;

        this.sendBookingFunc(this.props.funeralInfo, booking, contact).then(
            mutation => {
                if (mutation.data) {
                    if (mutation.data.createFuneralBookingReply) {
                        that.onCancel(true);
                    } else console.error('unexpected result: ', mutation.data);
                } else {
                    that.onGqlError('GQL Query returned no data');
                    that.setState({ sendingEmail: false });
                    return null;
                }
            },
            error => {
                that.setState({ sendingEmail: false });
                that.onGqlError('Failed to send email', error);
            }
        );
    }

    onGqlError(action, error) {
        console.error('onGqlError: ' + action, error);
    }

    sendBookingFunc = async (funeralInfo, booking, contact) => {
        let asyncQuery;
        const { label } = this.props;
        const { Notes } = booking;

        asyncQuery = await getClient().mutate({
            mutation: createFuneralBookingReply,
            variables: {
                input: {
                    BookingID: booking.ID,
                    DeliveryAddress: booking.DeliveryAddress,
                    DeliveryDate: booking.DeliveryDate,
                    DeliveryTime: booking.DeliveryTime,
                    EmailTo: contact.Email,
                    EmailBody:
                        (isNullOrUndefined(booking.Notes) ? '' : '<p>' + Notes.replace(/\n/g, '<br/>') + '</p>') +
                        '<p>' +
                        (isNullOrUndefined(booking.BookingItems) || booking.BookingItems.length <= 0
                            ? 'Request for: <strong>' + label + '</strong> ' + contact.Name + '<br/>Venue '
                            : renderProductsList(booking.BookingItems) + 'Delivery ') +
                        'Address: ' +
                        (isNullOrUndefined(booking.DeliveryAddress) ? 'Not required.' : booking.DeliveryAddress) +
                        '<br/>' +
                        'Required Date: ' +
                        (isNullOrUndefined(booking.DeliveryDate)
                            ? moment()
                                  .add(2, 'days')
                                  .format('D/MM/YYYY')
                            : moment(booking.DeliveryDate).format('D/MM/YYYY')) +
                        '<br/>' +
                        'Required Time: ' +
                        (isNullOrUndefined(booking.DeliveryTime)
                            ? '08:00 AM'
                            : niceTimeFromString(booking.DeliveryTime)) +
                        '</p>'
                }
            }
        });

        return asyncQuery;
    };
}

const gstIncPrice = item => {
    const product = ProductConfig.productMap[item.ProductID];
    if (!product) return 0;
    let needsGST = (!!product.original && !!product.original.gst) || false;
    if (!!item.VariationID && item.VariationID !== '0') {
        const variant = product.variations.find(e => e.id === item.VariationID);
        if (!!variant) needsGST = !!variant.gst;
    }
    return item.UnitPrice * (!!needsGST ? GST + 1 : 1); //incGST
};

const renderProductItem = (item, idx) => {
    const product = ProductConfig.productMap[item.ProductID];
    const margin = '0 5px 12px 0';
    return (
        <Fragment key={idx}>
            <div style={{ margin, width: 148, padding: 6, float: 'left', border: '1px solid #d5d5d5' }}>
                {product.image && (
                    <>
                        <img alt="product" src={product.image} width="100%" style={{ width: '100%' }} />
                        <br />
                    </>
                )}
                <small>
                    {item.Quantity}x <strong> {item.Title || product.title}</strong> at $
                    {prettyPrice(gstIncPrice(item), true)} each.
                    {!!item.Comment ? <i> {item.Comment}</i> : ''}
                </small>
            </div>
            {(4 + idx) % 3 ? '' : <div style={{ clear: 'both' }}></div>}
        </Fragment>
    );
};

const renderProductsList = items => {
    let orderTotal = 0;
    items.forEach(item => {
        orderTotal += item.Quantity * gstIncPrice(item);
    });
    return `Products required:</p><table width="320" style="margin:auto"><tbody><tr style="vertical-align:top"><td>
${items.map((item, idx) => renderToString(renderProductItem(item, idx))).join('')}</td></tr></tbody>
</table><p>Order Total: $${prettyPrice(orderTotal, true)} (includes GST)</p><p>`;
};

const createFuneralBookingReply = gql`
    mutation createFuneralBookingReply($input: CreateFuneralBookingReplyInput!) {
        createFuneralBookingReply(input: $input) {
            ID
            Booking {
                ID
            }
            ActionedBy {
                ID
                Email
            }
            Supplier {
                ID
                Name
            }
        }
    }
`;

const styles = ({ palette, spacing, funeralHome, breakpoints }) => ({
    root: {
        maxWidth: '800px',
        width: '100%',
        maxHeight: 'unset',
        [breakpoints.down('xs')]: {
            margin: spacing.unit
        }
    },
    diagHeader: {
        background: palette.contentForeground[funeralHome],
        padding: spacing.unit * 3
    },
    diagHeaderTitle: {
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 'lighter',
        lineHeight: 'normal'
    },
    diagHeaderSubtitle: {
        color: '#FFFFFF',
        fontSize: '36px',
        fontWeight: 'initial',
        lineHeight: 'normal'
    },
    diagHeaderIcon: {
        width: '70px',
        height: '70px',
        float: 'right',
        color: 'rgba(255,255,255,0.5)'
    },
    content: {
        width: '100%',
        padding: spacing.unit * 3,
        maxHeight: 'calc(100vh - 15rem)',
        overflow: 'auto',
        minHeight: 340,
        backgroundColor: '#fafafa',
        [breakpoints.down('xs')]: {
            maxHeight: 'calc(100vh - 12rem)',
            padding: spacing.unit
        }
    },
    diagActions: {
        padding: spacing.unit * 3,
        paddingTop: spacing.unit * 2,
        margin: 0,
        justifyContent: 'space-between'
    },
    regular: {
        fontSize: '16px',
        fontWeight: 'lighter'
    },

    greenButt: {
        border: '1px solid' + palette.button.save,
        backgroundColor: palette.button.save,
        '&:hover': {
            backgroundColor: palette.action.hover,
            color: palette.button.save
        }
    },
    starButton: {
        width: '70px',
        height: '70px',
        float: 'right',
        color: 'rgba(255,255,255,0.5)'
    }
});

export default withStyles(styles)(RequestBookingModal);
