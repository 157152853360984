import React, { Component, Fragment } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { applyAll } from '../../util/functions';
import { joinDefined } from '../../util/strings';
import TextField from './TextField';
import { getProperty, isNullOrUndefined } from '../../util/objects';
import { getValidationDecorations } from '../../util/validation';
import CustomAddressModal from './CustomAddressModal';
import InputAdornment from '@material-ui/core/InputAdornment';
import Spinner from '../Spinner';
import { OutlineButton } from './PrimaryButton';
import MapMarkerIcon from '../icon/MapMarkerIcon';
import CloseIcon from '../icon/CloseIcon';

export const COMPONENT = {
    businessName: 'businessName',
    line1: 'line1',
    line2: 'line2',
    city: 'city',
    state: 'state',
    country: 'country',
    code: 'code'
};

class AddressAutocomplete extends Component {
    state = {
        inputValue: null,
        focused: false,
        objectId: null,
        firstLoad: true,
        showCustomAddressModal: false
    };

    static getDerivedStateFromProps(parentProps, oldState) {
        if (isNullOrUndefined(parentProps.componentFields)) return null;

        const getValueFunc =
            (parentProps.form && (parentProps.form.getState || parentProps.form.getField)) || (_ => null);

        if (!(parentProps.form && parentProps.form.loading) && !!oldState.firstLoad) {
            //first load
            return { inputValue: getValueFromProps(parentProps), objectId: getValueFunc('ID'), firstLoad: false };
        } else if (parentProps.form && parentProps.form.loading && oldState.objectId !== null) {
            //reset for next
            return { inputValue: null, objectId: null };
        } else if (!oldState.focused && getValueFromProps(parentProps) !== oldState.inputValue) {
            // form values changed unexpectedly, so update state
            return { inputValue: getValueFromProps(parentProps) };
        }

        return null;
    }

    render() {
        const { inputValue, showCustomAddressModal } = this.state;
        const { form, onlySuburbs, componentFields, name, label } = this.props;

        const searchOptions = {
            location: !(window.google && window.google.maps && window.google.maps.LatLng)
                ? null
                : new window.google.maps.LatLng(-34, 151),
            radius: 2000
        };

        if (!(window.google && window.google.maps && window.google.maps.places)) {
            return (
                <div style={{ width: '100%' }}>
                    {Object.values(componentFields).map((fName, idx) => (
                        <TextField key={idx + fName} form={form} name={fName} label={fName.replace(/.*?\./gi, '')} />
                    ))}
                </div>
            );
        }

        if (!!onlySuburbs) searchOptions.types = ['(cities)'];

        return (
            <Fragment>
                <PlacesAutocomplete
                    value={inputValue || ''}
                    onChange={this.onChange}
                    onSelect={this.onSelect}
                    onError={this.handleError}
                    searchOptions={searchOptions}
                    highlightFirstSuggestion
                >
                    {this.renderContent}
                </PlacesAutocomplete>
                <CustomAddressModal
                    onClose={() => this.setState({ showCustomAddressModal: false })}
                    onApply={() => this.onCustomAddressModalApply()}
                    open={showCustomAddressModal}
                    name={name + '_Custom'}
                    form={form}
                    label={label}
                    customAddressFields={componentFields}
                />
            </Fragment>
        );
    }

    getComponents(geoCodeComponents) {
        const {
            subpremise,
            street_number,
            route,
            locality,
            administrative_area_level_1,
            country,
            postal_code
        } = geoCodeComponents.reduce((map, c) => {
            let property = c.types[0];
            /*
            kind of hacky, but weird. if you enter a capital city, it craps out and loses the city name, because
            it expects 'locality' to be c.types[0]. but its 'colloquial_area' instead. not sure exactly the best
            fix for this, so for the moment, just swap it to locality.
            */
            if (c.types.indexOf('locality') !== -1 || c.types.indexOf('sublocality') !== -1) property = 'locality';

            map[property] = c;
            return map;
        }, {});

        const num = joinDefined([subpremise && subpremise.short_name, street_number && street_number.short_name], '/');
        return {
            line1: joinDefined([num, route && route.long_name], ' '),
            city: locality && locality.short_name,
            state: administrative_area_level_1 && administrative_area_level_1.short_name,
            country: country && country.long_name,
            code: postal_code && postal_code.short_name
        };
    }

    onBlur = () => {
        this.setState({
            focused: false,
            inputValue: getValueFromProps(this.props)
        });
    };

    onFocus = e => {
        this.setState({ focused: true, inputValue: e.target.value });
    };

    onChange = inputValue => {
        this.setState({ inputValue });
    };

    onClear = () => {
        const that = this;
        const { form, componentFields, onSelect, name } = that.props;
        const newState = Object.keys(componentFields).reduce((acc, componentName) => {
            const formFieldName = componentFields[componentName];
            acc[formFieldName] = null;
            return acc;
        }, {});
        if (typeof form !== 'undefined') {
            if (form.setField) {
                form.setField(newState);
            } else {
                form.setState(newState);
            }
        }
        if (onSelect) onSelect('', newState);
        if (typeof form !== 'undefined') {
            form.getValidation(name, true);
        }
        that.setState({
            inputValue: getValueFromProps(that.props)
        });
    };

    onSelect = inputValue => {
        const that = this;
        geocodeByAddress(inputValue).then(results => {
            const { onReturnValue } = this.props;
            if (!isNullOrUndefined(onReturnValue)) {
                onReturnValue(inputValue);
            }

            const { form, componentFields, onSelect, name } = that.props;

            if (!results.length) return;
            const components = that.getComponents(results[0].address_components);
            const newState = Object.keys(componentFields).reduce((acc, componentName) => {
                const formFieldName = componentFields[componentName];
                acc[formFieldName] = components[componentName] || null;
                return acc;
            }, {});

            if (typeof form !== 'undefined') {
                if (form.setField) {
                    form.setField(newState);
                } else {
                    form.setState(newState);
                }
            }
            if (onSelect) onSelect(inputValue, newState);

            if (form && !form.getValidation) {
                // eslint-disable-next-line no-console
                console.warn(name + ' form does not contain validation function!');
            } else if (form && name) {
                form.getValidation(name, true);
            }

            that.setState({ inputValue: getValueFromProps(that.props) });
        });
    };

    handleError = (status, clearSuggestions) => {
        // eslint-disable-next-line no-console
        console.error('Google Maps API returned error with status: ', status);
        clearSuggestions();
    };

    renderContent = ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        const {
            id,
            placeholder,
            label,
            required,
            allowCustomAddress,
            componentFields,
            readOnly,
            disabled,
            classes,
            className = ''
        } = this.props;
        const { inputValue } = this.state;
        const inputProps = getInputProps({ placeholder });
        inputProps.onBlur = applyAll(inputProps.onBlur, this.onBlur);
        inputProps.onFocus = applyAll(inputProps.onFocus, this.onFocus);
        inputProps.label = label;
        inputProps.required = required;

        const decorations = getValidationDecorations(this.props, classes.root, componentFields);

        return (
            <div className={`${classes.root} ${className}`}>
                <div className={classes.textRoot}>
                    <TextField
                        {...inputProps}
                        id={id}
                        validationResult={decorations.validationResult}
                        readOnly={readOnly}
                        disabled={disabled}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" className={classes.iconContainer}>
                                    {!!loading ? <Spinner /> : <MapMarkerIcon className={classes.icon} />}
                                </InputAdornment>
                            ),
                            endAdornment: !readOnly && !disabled && (
                                <InputAdornment position="end">
                                    {!!allowCustomAddress && !inputValue && (
                                        <OutlineButton
                                            forModal
                                            title="Enter a specific location"
                                            text="Add Specific&hellip;"
                                            className={classes.addNewButton}
                                            disabled={disabled}
                                            onClick={() => this.setState({ showCustomAddressModal: true })}
                                        />
                                    )}
                                    {!!inputValue && (
                                        <span title="Remove">
                                            <CloseIcon
                                                style={{
                                                    cursor: 'pointer',
                                                    backgroundColor: 'grey',
                                                    padding: 1,
                                                    fontSize: 12,
                                                    borderRadius: 36,
                                                    color: 'white'
                                                }}
                                                onClick={this.onClear}
                                            />
                                        </span>
                                    )}
                                </InputAdornment>
                            )
                        }}
                    />
                    {this.renderSuggestions(suggestions, getSuggestionItemProps)}
                </div>
            </div>
        );
    };

    renderSuggestions(suggestions, getSuggestionItemProps) {
        const { focused } = this.state;
        if (!focused || !suggestions.length) return false;
        const { classes } = this.props;
        return (
            <Paper className={classes.paper} square>
                {suggestions.map((suggestion, index) =>
                    this.renderSuggestion(suggestion, index, getSuggestionItemProps)
                )}
            </Paper>
        );
    }

    renderSuggestion(suggestion, index, getSuggestionItemProps) {
        const { classes } = this.props;
        const props = getSuggestionItemProps(suggestion);
        return (
            <MenuItem {...props} className={classes.item} selected={suggestion.active} key={index}>
                {suggestion.description}
            </MenuItem>
        );
    }

    onCustomAddressModalApply() {
        const inputValue = getValueFromProps(this.props);
        this.setState({ inputValue });

        const { form, componentFields, onSelect } = this.props;
        if (onSelect) {
            const newState = Object.keys(componentFields).reduce((acc, key) => {
                acc[key] = form && form.getField(componentFields[key]);
                return acc;
            }, {});
            onSelect(inputValue, newState);
        }
    }
}

const getValueFromProps = props => {
    const { form, componentFields, value } = props;
    if (!form) return value;

    return getValueFromFormState(componentFields, form.fields || form.state);
};

const getValueFromFormState = (componentFields, state) => {
    // Shorthand to get value of address component field
    const v = name => getProperty(state, componentFields[name]);
    return joinDefined(
        [
            joinDefined([v(COMPONENT.line1), v(COMPONENT.line2)], ' '),
            joinDefined([v(COMPONENT.city), v(COMPONENT.state), v(COMPONENT.code)], ' '),
            v(COMPONENT.country)
        ],
        ', '
    );
};

const styles = ({ palette }) => ({
    root: {
        display: 'inline-flex',
        width: '100%'
    },
    textRoot: {
        position: 'relative',
        width: '100%',
        minWidth: 300,
        '& button': {
            width: 0,
            padding: 0,
            opacity: 0,
            overflow: 'hidden',
            transition: 'opacity 0.4s, width 0.5s ease-out 0s'
        },
        '&:hover button': {
            width: 100,
            opacity: 1,
            transition: 'opacity 0.25s'
        }
    },
    paper: {
        position: 'absolute',
        zIndex: 100,
        left: 0,
        right: 0,
        padding: '8px 0'
    },
    item: {
        padding: '6px 16px'
    },
    addNewButton: {
        display: 'inline-block',
        margin: '0 -4px 0 0',
        borderRadius: '20px 3px 3px 20px',
        height: 28,
        minWidth: 'unset',
        whiteSpace: 'pre',
        padding: '0 6px',
        textAlign: 'center',
        '& > span': {
            fontSize: '0.75em'
        }
    },
    iconContainer: {
        alignSelf: 'center'
    },
    icon: {
        fontSize: 16,
        color: palette.action.active
    }
});

export default withStyles(styles)(AddressAutocomplete);
