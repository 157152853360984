import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import GqlAutocomplete from './GqlAutocomplete';
import MusicModal from './MusicModal';
import { withStyles } from '@material-ui/core';
import { OutlineButton } from './PrimaryButton';
import { isNullOrUndefined } from '../../util/objects';

class MusicAutoComplete extends Component {
    state = {
        showMusicModal: false
    };

    render() {
        const { showMusicModal } = this.state;
        const { form, classes, disabled, onSelect, placeholder } = this.props;

        return (
            <Fragment>
                <MusicModal onClose={song => this.onCloseMusicModal(song)} open={showMusicModal} />

                <div className={classes.root}>
                    <GqlAutocomplete
                        disabled={disabled}
                        placeholder={placeholder}
                        readOneQuery={readOneMusicLibraryQuery}
                        readAllQuery={readMusicLibrariesQuery}
                        convertResults={data => {
                            if (!data || !data.readMusicLibraries) return null;
                            return data.readMusicLibraries.edges
                                .sort((a, b) => (a.node.SongName < b.node.SongName ? -1 : 1))
                                .map(e => e.node);
                        }}
                        onSelect={onSelect}
                        clearOnSelect
                        form={form}
                        labelFieldFunc={result => (
                            <div style={{ whiteSpace: 'normal', lineHeight: 1 }}>
                                <span>&ldquo;{result.SongName}&rdquo;</span>
                                <small style={{ paddingLeft: 12 }}>
                                    {result.Artist}
                                    {result.Album ? <> ({result.Album})</> : null}
                                </small>
                            </div>
                        )}
                        addNewButton={
                            <OutlineButton
                                className={classes.addNewButton}
                                text="Add new"
                                onClick={() => this.setState({ showMusicModal: true })}
                                disabled={disabled}
                            />
                        }
                    />
                </div>
            </Fragment>
        );
    }

    onCloseMusicModal(song) {
        this.setState({ showMusicModal: false });

        if (isNullOrUndefined(song)) return;

        const { onAddNewSong } = this.props;

        if (onAddNewSong) onAddNewSong(song);
    }
}

const readMusicLibrariesQuery = gql`
    query ReadSongs($contains: String) {
        readMusicLibraries(contains: $contains) {
            edges {
                node {
                    ID
                    SongName
                    Album
                    Artist
                    TrackLocation
                }
            }
            pageInfo {
                totalCount
            }
        }
    }
`;

const readOneMusicLibraryQuery = gql`
    query ReadOneSong($id: ID!) {
        readOneMusicLibrary(ID: $id) {
            ID
            SongName
            Album
            Artist
            TrackLocation
        }
    }
`;

const styles = () => ({
    root: {
        display: 'inline-flex',
        width: '100%',
        '& button': {
            width: 0,
            padding: 0,
            opacity: 0,
            overflow: 'hidden',
            transition: 'opacity 0.4s, width 0.5s ease-out 0s'
        },
        '&:hover button': {
            width: 80,
            opacity: 1,
            transition: 'opacity 0.25s'
        }
    },
    addNewButton: {
        display: 'inline-block',
        margin: '0 -4px 0 0',
        borderRadius: '20px 4px 4px 20px',
        height: 28,
        minWidth: 'unset',
        whiteSpace: 'pre',
        padding: '0 8px',
        textAlign: 'center',
        '& > span': {
            fontSize: '0.75em'
        }
    }
});

export default withStyles(styles)(MusicAutoComplete);
